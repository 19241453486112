<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Service Report"
              url="/pages/services-tools/service-report/manage"
              :canAdd="false"
              :isEditable="false"
              :data-source="dataSource"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadJobOrders()"
              @on-row-click="(v) => onViewDetail(v)"
            >
              <template #columns>
                <DxColumn data-field="ticket_no" caption="Ticket No." />
                <DxColumn data-field="company_name" caption="Company" />
                <DxColumn data-field="address" caption="Job Site" />
                <DxColumn
                  data-field="preview_report_date"
                  caption="Report Date"
                />
              </template>

              <template #action-buttons>
                <DxButton icon="copy" hint="Duplicate" :onClick="onDuplicate" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text> </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import jobOrderApi from '@api/joborder_headers';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';
import { queryParameters } from '@/schema';

export default {
  name: 'ServiceReportPage',
  components: {},
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
  }),
  mounted() {
    this.loadJobOrders();
  },
  methods: {
    loadJobOrders() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `job_status=5`,
      };

      jobOrderApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            for (let i = 0; i < data.length; i++) {
              const e = data[i];

              e.preview_report_date = unixToDisplayDateTime(e.report_date);
            }

            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },

    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      const url = `/pages/services-tools/service-report/view?id=${event.key}`;
      this.$router.push(url);
    },
    onUpdate(event) {
      const data = event.data;
    },
    onDelete(event) {
      //
    },
    onDuplicate(event) {
      const { key: from_id } = event.row;
      const formData = { from_id };

      jobOrderApi
        .duplicate(formData)
        .then(({ data }) => {
          if (data) {
            const url = `/pages/services-tools/job-order/view?id=${data.id}`;
            this.$router.push(url);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
